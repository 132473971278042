import { useEffect, useState } from "react";
import { userInputEvents } from "./helper";

export const useUserInteraction = () => {
  const [hasUserInteracted, setHasUserInteracted] = useState(false);

  useEffect(() => {
    const callback = () => {
      setHasUserInteracted(true);
      userInputEvents.forEach((eventName) => {
        document.removeEventListener(eventName, callback);
      });
    };

    userInputEvents.forEach((eventName) => {
      document.addEventListener(eventName, callback);
    });

    return () =>
      userInputEvents.forEach((eventName) => {
        document.removeEventListener(eventName, callback);
      });
  }, []);

  return {
    hasUserInteracted,
  };
};
