import { useUserInteraction } from "../../hooks/useUserInteraction/useUserInteraction";
import { Text } from "../Text";
import type { BreadcrumbsProps } from "./Breadcrumbs";
import { Link, List, ListItem, NextLink } from "./Breadcrumbs.styles";
import { Separator } from "./Separator";

export const BreadcrumbsDesktop = ({
  breadcrumbs,
  homeUrl,
  shouldUseNextLink = false,
  shouldRenderCrumbsOnUserInteraction = false,
}: BreadcrumbsProps) => {
  const { hasUserInteracted } = useUserInteraction();

  if (breadcrumbs.length === 0) return null;

  const LinkComponent = shouldUseNextLink ? NextLink : Link;

  return (
    <List
      data-testid="breadcrumbs"
      itemScope={true}
      itemType="https://schema.org/BreadcrumbList"
      style={{ minHeight: "18px" }} // Todo: remove this https://xxlsports.atlassian.net/browse/XD-15863
    >
      {breadcrumbs.map(({ name, url }, index) => {
        const isLast = index === breadcrumbs.length - 1;

        if (shouldRenderCrumbsOnUserInteraction && !hasUserInteracted) {
          return null;
        }

        return (
          <ListItem
            key={index}
            itemProp="itemListElement"
            itemScope={true}
            itemType="https://schema.org/ListItem"
          >
            <LinkComponent
              href={`${homeUrl}${url}`}
              itemProp="item"
              scroll={false}
            >
              <Text
                fontFamily={isLast ? "bold" : "regular"}
                color={isLast ? "webBlack" : "webGray"}
                itemProp="name"
              >
                {name}
              </Text>
            </LinkComponent>
            <meta itemProp="position" content={String(index + 1)} />
            {isLast ? null : <Separator />}
          </ListItem>
        );
      })}
    </List>
  );
};
